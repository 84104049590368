import React from "react";
import { Helmet } from "react-helmet-async";
import "./faq.css";

export const DividerWhite = () => (
  <section>
    <div className="dividerwhite"></div>
  </section>
);

const FAQ = () => {
  return (
    <section>
      <Helmet>
        {/* Essential/Basic Meta Tags */}
        <title>
          The Framers' Method: Electoral College & Hamilton Method Explained -
          Frequently Asked Questions
        </title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="Find the answers to frequently asked questions about the Electoral College."
        />
        <meta
          name="keywords"
          content="electoral college, presidential elections, american politics, electors, electoral votes, president, constitution"
        />
        <meta name="author" content="Dustin Taylor" />
        <meta name="language" content="English" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html"></meta>
        {/* Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.framersmethod.com/" />
        <meta property="og:site_name" content="The Framers' Method" />
        <meta
          property="og:title"
          content="The Framers' Method - Frequently Asked Questions"
        />
        <meta
          property="og:description"
          content="Find the answers to frequently asked questions about the Electoral College."
        />
        <meta
          property="og:image"
          content="https://www.framersmethod.com/framersmethodlogo-withbackground.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@framersmethod" />
        <meta
          name="twitter:title"
          content="The Framers' Method - Frequently Asked Questions"
        />
        <meta
          name="twitter:description"
          content="Find the answers to frequently asked questions about the Electoral College."
        />
        <meta
          name="twitter:image"
          content="https://www.framersmethod.com/framersmethodlogo-withbackground.png"
        />
      </Helmet>
      <div id="header2" class="main">
        <DividerWhite />
        <h1 class="faq-h1">Frequently Asked Questions</h1>
        <DividerWhite />
        <h2 class="faq-h2">Electoral College</h2>
        <a class="faq-a" href="#a1">
          <p class="faq-p"> What is the Electoral College?</p>
        </a>
        <a class="faq-a" href="#a2">
          <p class="faq-p">What does the Electoral College do?</p>
        </a>
        <a class="faq-a" href="#a3">
          <p class="faq-p"> What does the Electoral College mean?</p>
        </a>
        <a class="faq-a" href="#a4">
          <p class="faq-p"> What are electoral votes?</p>
        </a>
        <a class="faq-a" href="#a5">
          <p class="faq-p"> Does the Electoral College elect the president?</p>
        </a>
        <a class="faq-a" href="#a6">
          <p class="faq-p"> Why is the Electoral College important?</p>
        </a>
        <a class="faq-a" href="#a7">
          <p class="faq-p"> Why does the Electoral College exist?</p>
        </a>
        <h2 class="faq-h2">Electors</h2>
        <a class="faq-a" href="#b1">
          <p class="faq-p">What are the Electoral College electors?</p>
        </a>
        <a class="faq-a" href="#b2">
          <p class="faq-p">Who elects the Electoral College?</p>
        </a>
        <a class="faq-a" href="#b3">
          <p class="faq-p">Are members of the Electoral College anonymous?</p>
        </a>
        <a class="faq-a" href="#b4">
          <p class="faq-p">Are members of the Electoral College paid?</p>
        </a>
        <a class="faq-a" href="#b5">
          <p class="faq-p">Are members of the Electoral College elected? </p>
        </a>
        <a class="faq-a" href="#b6">
          <p class="faq-p">Who chooses the members of the Electoral College?</p>
        </a>
        <h2 class="faq-h2">Electoral College Voting</h2>
        <a class="faq-a" href="#c1">
          <p class="faq-p">
            What if the Electoral College has a tie vote? Can the Electoral
            College tie?
          </p>
        </a>
        <a class="faq-a" href="#c2">
          <p class="faq-p">
            What if the Electoral College doesn’t achieve a majority vote?
          </p>
        </a>
        <a class="faq-a" href="#c3">
          <p class="faq-p">Who breaks an Electoral College tie?</p>
        </a>

        <h2 class="faq-h2">Origin</h2>
        <a class="faq-a" href="#d1">
          <p class="faq-p">Who created the Electoral College?</p>
        </a>
        <a class="faq-a" href="#d2">
          <p class="faq-p">
            When was the Electoral College first used? When was the Electoral
            College started?
          </p>
        </a>
        <a class="faq-a" href="#d3">
          <p class="faq-p">Why was the Electoral College created?</p>
        </a>
        <a class="faq-a" href="#d4">
          <p class="faq-p">Where did the Electoral College come from?</p>
        </a>
        <a class="faq-a" href="#d5">
          <p class="faq-p">
            Where is the Electoral College in the Constitution?
          </p>
        </a>

        <h2 class="faq-h2">Calendar</h2>
        <a class="faq-a" href="#e1">
          <p class="faq-p">
            When is the Electoral College used? When does the Electoral College
            meet?
          </p>
        </a>
        <a class="faq-a" href="#e2">
          <p class="faq-p">
            When are the members of the Electoral College chosen?
          </p>
        </a>
        <a class="faq-a" href="#e3">
          <p class="faq-p">
            Where does the Electoral College meet? Where do Electoral College
            electors vote?
          </p>
        </a>

        <h2 class="faq-h2">Constitution</h2>
        <a class="faq-a" href="#f1">
          <p class="faq-p">
            Why is the Electoral College necessary to choose the president?
          </p>
        </a>
        <a class="faq-a" href="#f2">
          <p class="faq-p">Which amendment created the Electoral College?</p>
        </a>
        <a class="faq-a" href="#f3">
          <p class="faq-p">Can the Electoral College be reformed?</p>
        </a>

        <h2 class="faq-h2">Operation</h2>
        <a class="faq-a" href="#g1">
          <p class="faq-p">How does the Electoral College work?</p>
        </a>
        <a class="faq-a" href="#g2">
          <p class="faq-p">
            How are electoral votes determined for each state?
          </p>
        </a>
        <a class="faq-a" href="#g3">
          <p class="faq-p">
            What’s the simplest way to explain the Electoral College?
          </p>
        </a>
        <a class="faq-a" href="#g4">
          <p class="faq-p">Are Electoral College votes based on population?</p>
        </a>

        <h2 class="faq-h2">Faithless Electors</h2>
        <a class="faq-a" href="#h1">
          <p class="faq-p">
            Can members of the Electoral College change their mind? Can members
            of the Electoral College go rogue?
          </p>
        </a>
        <a class="faq-a" href="#h2">
          <p class="faq-p">
            When has the Electoral College voted differently than the popular
            vote?
          </p>
        </a>

        <h2 class="faq-h2">Miscellaneous</h2>
        <a class="faq-a" href="#i1">
          <p class="faq-p">Is the Electoral College capitalized?</p>
        </a>
        <a class="faq-a" href="#i2">
          <p class="faq-p">Can Electoral College votes be split?</p>
        </a>
        <a class="faq-a" href="#i3">
          <p class="faq-p">Where is the Electoral College located?</p>
        </a>
        <a class="faq-a" href="#i4">
          <p class="faq-p">
            Why is the Electoral College different from the popular vote?
          </p>
        </a>
        <a class="faq-a" href="#i5">
          <p class="faq-p">
            What are the pros and cons of the Electoral College?
          </p>
        </a>
        <a class="faq-a" href="#i6">
          <p class="faq-p" style={{ paddingBottom: "30px" }}>
            Are Electoral College votes important?
          </p>
        </a>
        <DividerWhite />
        <h1 class="faq-h1">Answers</h1>
        <DividerWhite />
        <h2 class="faq-h2" style={{ paddingTop: "30px" }}>
          Electoral College
        </h2>
        <div class="divider2" id="a1"></div>
        <h4 class="faq-h4">What is the Electoral College?</h4>
        <p class="faq-p">
          The Electoral College is the process used to determine the next
          president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a2"></div>
        <h4 class="faq-h4">What does the Electoral College do?</h4>
        <p class="faq-p">
          The Electoral College gives each state a number of electors, based on
          population, to choose the next president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a3"></div>
        <h4 class="faq-h4">What does the Electoral College mean?</h4>
        <p class="faq-p">
          The Electoral College is a reference for how the United States chooses
          the next president. It is often used to distinguish the process that
          is different from the popular vote method, which the United States
          does not use to choose the president.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a4"></div>
        <h4 class="faq-h4">What are electoral votes?</h4>
        <p class="faq-p">
          Electoral votes are individual votes cast by electors throughout the
          several states.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a5"></div>
        <h4 class="faq-h4">Does the Electoral College elect the president? </h4>
        <p class="faq-p">
          Yes, the Electoral College elects the president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a6"></div>
        <h4 class="faq-h4">Why is the Electoral College important?</h4>
        <p class="faq-p">
          The Electoral College is important because it is the constitutional
          process to choose the next president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="a7"></div>
        <h4 class="faq-h4">Why does the Electoral College exist?</h4>
        <p class="faq-p">
          The Electoral College exists to select the president of the United
          States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Electors</h2>
        <div class="divider2" id="b1"></div>
        <h4 class="faq-h4">What are the Electoral College electors?</h4>
        <p class="faq-p">
          The electors in the Electoral College are individuals who cast ballots
          in their home states for the next president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="b2"></div>
        <h4 class="faq-h4">Who elects the Electoral College?</h4>
        <p class="faq-p">
          The Constitution determines that each state shall decide how electors
          are chosen. However, in practice, two groups elect the members of the
          Electoral College. First, parties within each state will choose their
          slate of electors. Depending on the state, these slates of electors
          may be chosen through a party convention, from the state’s central
          committee, or from the presidential campaign itself.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="b3"></div>
        <h4 class="faq-h4">Are members of the Electoral College anonymous?</h4>
        <p class="faq-p">
          No, the members of the Electoral College are not anonymous. Their
          names are on party slates before the election and their votes for
          president are public record.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="b4"></div>
        <h4 class="faq-h4">Are members of the Electoral College paid?</h4>
        <p class="faq-p">
          Electors are not paid but usually receive some type of stipend to
          cover the cost of travel to the state capital.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="b5"></div>
        <h4 class="faq-h4">Are members of the Electoral College elected? </h4>
        <p class="faq-p">
          Yes, the members of the Electoral College are elected in each state,
          however, voters typically cast ballots for a presidential candidate
          instead of voting directly for individual electors.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="b6"></div>
        <h4 class="faq-h4">
          Who chooses the members of the Electoral College?
        </h4>
        <p class="faq-p">
          The states choose the members of the Electoral College through
          state-wide popular votes. Nebraska and Maine choose electors through
          popular votes in congressional districts and the remaining two
          electors through state-wide popular votes.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Electoral College Voting</h2>
        <div class="divider2" id="c1"></div>
        <h4 class="faq-h4">
          What if the Electoral College has a tie vote? Can the Electoral
          College tie?
        </h4>
        <p class="faq-p">
          If the Electoral College results in a tie vote, then the process to
          choose the next president moves to the House of Representatives. In
          the House, each state delegation will have one vote to choose the next
          president from a list of the top three electoral vote recipients until
          one candidate receives a majority.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="c2"></div>
        <h4 class="faq-h4">
          What if the Electoral College doesn’t achieve a majority vote?
        </h4>
        <p class="faq-p">
          If the Electoral College results in no presidential candidates
          receiving a majority vote, then the process to choose the next
          president moves to the House of Representatives. In the House, each
          state delegation will have one vote to choose the next president from
          a list of the top three electoral vote recipients until one candidate
          receives a majority.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="c3"></div>
        <h4 class="faq-h4">Who breaks an Electoral College tie?</h4>
        <p class="faq-p">
          In the event of a tie in the Electoral College, the House of
          Representatives will choose the next president by state delegation.
          For example, in 2024, the state of Michigan has thirteen
          representatives, seven Democrats and six Republicans. Assuming the
          seven Democrats vote for their party, the state of Michigan will award
          the Democratic presidential nominee one vote. To win, someone must
          receive an absolute majority, or 26 state-delegation votes, to become
          president.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Origin</h2>
        <div class="divider2" id="d1"></div>
        <h4 class="faq-h4">Who created the Electoral College?</h4>
        <p class="faq-p">
          The Electoral College was created by the framers of the Constitution
          at Independence Hall in Philadelphia, Pennsylvania in 1787.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="d2"></div>
        <h4 class="faq-h4">
          When was the Electoral College first used? When was the Electoral
          College started?
        </h4>
        <p class="faq-p">
          The first Electoral College was used on February 4, 1789, to choose
          George Washington as the first president of the United States.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="d3"></div>
        <h4 class="faq-h4">Why was the Electoral College created?</h4>
        <p class="faq-p">
          The Electoral College was created after debating three different
          methods to choose the president: the legislature method, the elector's
          convention method, and the popular vote. The framers ended up
          compromising by creating a hybrid method of the legislature and
          elector's convention methods. The popular vote was highly unpopular
          with the framers at the Constitutional Convention.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="d4"></div>
        <h4 class="faq-h4">Where did the Electoral College come from?</h4>
        <p class="faq-p">
          The Electoral College came from a series of debates during the
          Constitutional Convention of 1787. After debating the legislature
          method and the elector's convention method, the framers compromised
          and blended the two ideas.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="d5"></div>
        <h4 class="faq-h4">
          Where is the Electoral College in the Constitution?
        </h4>
        <p class="faq-p">
          The language related to the Electoral College may be found in Article
          II, Section I, Clause 2 & 3 of the U.S. Constitution. The full text
          may be found in the{" "}
          <a
            class="faq-a"
            href="https://www.archives.gov/founding-docs/constitution-transcript#2-1"
            target="_blank"
            rel="noreferrer"
          >
            archives
          </a>
          .
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Calendar</h2>
        <div class="divider2" id="e1"></div>
        <h4 class="faq-h4">
          When is the Electoral College used? When does the Electoral College
          meet?
        </h4>
        <p class="faq-p">
          The electors of the Electoral College will assemble in their
          respective state capitals on the first Tuesday after the second
          Wednesday in December after the general election. On this day, the
          electors will cast their ballots and then mail them to Congress. In
          2024, this day falls on December 17.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="e2"></div>
        <h4 class="faq-h4">
          When are the members of the Electoral College chosen?
        </h4>
        <p class="faq-p">
          The members of the Electoral College, also known as electors, are
          chosen in two steps. First, the political parties of the presidential
          candidates will choose slates of electors through a party convention,
          by a party’s state central committee, or by the presidential campaign
          itself. Then, whichever presidential candidate wins a state’s popular
          vote will determine which slate of electors will be able to vote for
          president.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="e3"></div>
        <h4 class="faq-h4">
          Where does the Electoral College meet? Where do Electoral College
          electors vote?
        </h4>
        <p class="faq-p">
          The electors of the Electoral College meet in the state capitals of
          their respective states to cast ballots for the president.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Constitution</h2>
        <div class="divider2" id="f1"></div>
        <h4 class="faq-h4">
          Why is the Electoral College necessary to choose the president?
        </h4>
        <p class="faq-p">
          The Electoral College is necessary to choose the president because it
          derives from Article II of the U.S. Constitution.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="f2"></div>
        <h4 class="faq-h4">Which amendment created the Electoral College?</h4>
        <p class="faq-p">
          The Electoral College was written into the original Constitution,
          however, the Twelfth Amendment modified the process to create today’s
          version of choosing the president.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="f3"></div>
        <h4 class="faq-h4">Can the Electoral College be reformed?</h4>
        <p class="faq-p">
          Yes, the Electoral College can be reformed. Just like any other aspect
          of the U.S. Constitution, it can be altered or removed through an
          amendment to the Constitution.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Operation</h2>
        <div class="divider2" id="g1"></div>
        <h4 class="faq-h4">How does the Electoral College work?</h4>
        <p class="faq-p">
          The Electoral College used today works by candidates competing to win
          the popular vote in individual states to win each state’s respective
          electoral votes. Whichever candidate receives 270 or more electoral
          votes will win the presidential election.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="g2"></div>
        <h4 class="faq-h4">
          How are electoral votes determined for each state?
        </h4>
        <p class="faq-p">
          Electoral votes are determined by the number of representatives each
          state has been allotted in the House of Representatives plus its two
          senators. For example, Michigan has 13 representatives and 2 senators,
          giving them a total of 15 electors.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="g3"></div>
        <h4 class="faq-h4">
          What’s the simplest way to explain the Electoral College?
        </h4>
        <p class="faq-p">
          The most simple way to explain the Electoral College is a candidate
          must win 270 electoral votes or more to win the presidential election.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="g4"></div>
        <h4 class="faq-h4">Are Electoral College votes based on population?</h4>
        <p class="faq-p">
          Yes, the electoral votes used in the Electoral College are based on
          population. Each state is given representatives in the House of
          Representatives based on population.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Faithless Electors</h2>
        <div class="divider2" id="h1"></div>
        <h4 class="faq-h4">
          Can members of the Electoral College change their mind? Can members of
          the Electoral College go rogue?
        </h4>
        <p class="faq-p">
          Yes, electors of the Electoral College can vote differently from the
          results of their state’s popular vote total. They are often referred
          to as faithless electors. It’s difficult to know the number from past
          elections because the criteria may be different for counting faithless
          electors. That being said, faithless electors have never overturned a
          presidential election.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="h2"></div>
        <h4 class="faq-h4">
          When has the Electoral College voted differently than the popular
          vote?
        </h4>
        <p class="faq-p">
          The Electoral College vote has been different than the popular vote
          five times in U.S. history. This occurred in the 1824, 1876, 1888,
          2000, and 2016 elections.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2"></div>

        <h2 class="faq-h2">Miscellaneous</h2>
        <div class="divider2" id="i1"></div>
        <h4 class="faq-h4">Is the Electoral College capitalized?</h4>
        <p class="faq-p">Yes, the Electoral College is capitalized.</p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="i2"></div>
        <h4 class="faq-h4">Can Electoral College votes be split?</h4>
        <p class="faq-p">
          No, electoral votes in the Electoral College cannot be split.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="i3"></div>
        <h4 class="faq-h4">Where is the Electoral College located?</h4>
        <p class="faq-p">
          The Electoral College is located in every state capital and
          Washington, D.C.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="i4"></div>
        <h4 class="faq-h4">
          Why is the Electoral College different from the popular vote?
        </h4>
        <p class="faq-p">
          The Electoral College is different from the popular vote because the
          vote to choose the president is the result of counting electoral votes
          rather than votes by individual voters.
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="i5"></div>
        <h4 class="faq-h4">
          What are the pros and cons of the Electoral College?
        </h4>
        <p class="faq-p">
          The Electoral College is a very controversial institution, so the pros
          and cons may differ from person to person. However, broadly speaking,
          an often-cited advantage is that presidential candidates must win
          states throughout the country, for example, more rural states, instead
          of population centers. The most often disadvantage discussed is that
          the candidate with the most votes does not determine the winner.{" "}
        </p>
        <a class="faq-a" href="#header2">
          Return to Top
        </a>
        <div class="divider2" id="i6"></div>
        <h4 class="faq-h4">Are Electoral College votes important?</h4>
        <p class="faq-p">
          Electoral votes are important because they are required to win the
          presidential election.
        </p>
        <a class="faq-a" href="#header2">
          <p style={{ paddingBottom: "30px" }}>Return to Top</p>
        </a>
        <DividerWhite />
      </div>
    </section>
  );
};

export default FAQ;
