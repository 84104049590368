import React from "react";
import { SocialMediaBar } from "../socialmediabar";
import { Helmet } from "react-helmet-async";
import "./generalcaucus.css";

const GeneralCaucus = () => {
  return (
    <section>
      <Helmet>
        {/* Essential/Basic Meta Tags */}
        <title>
          The Framers' Method: Electoral College & Hamilton Method Explained -
          The General Caucus
        </title>
        <meta charset="utf-8" />
        <meta
          name="description"
          content="The General Caucus is a series of tiered meetings for selecting politicians to specific offices and will prevent the oligarchs and tyrannically minded candidates from controling American politics."
        />
        <meta
          name="keywords"
          content="caucus, general caucus, elections, american politics, electors, republic, president, constitution, democracy, electors convention"
        />
        <meta name="author" content="Dustin Taylor" />
        <meta name="language" content="English" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html"></meta>
        {/* Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.framersmethod.com/" />
        <meta property="og:site_name" content="The Framers' Method" />
        <meta
          property="og:title"
          content="The Framers' Method - The General Caucus"
        />
        <meta
          property="og:description"
          content="The General Caucus is a series of tiered meetings for selecting politicians to specific offices and will prevent the oligarchs and tyrannically minded candidates from controling American politics."
        />
        <meta
          property="og:image"
          content="https://www.framersmethod.com/framersmethodlogo-withbackground.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@framersmethod" />
        <meta
          name="twitter:title"
          content="The Framers' Method - The General Caucus"
        />
        <meta
          name="twitter:description"
          content="The General Caucus is a series of tiered meetings for selecting politicians to specific offices and will prevent the oligarchs and tyrannically minded candidates from controling American politics."
        />
        <meta
          name="twitter:image"
          content="https://www.framersmethod.com/framersmethodlogo-withbackground.png"
        />
      </Helmet>
      <div class="caucus-logo">
        <img src="../generalcaucuslogo.png" alt="The General Caucus" />
      </div>
      <SocialMediaBar />
      <section className="caucus-template" style={{ backgroundColor: "white" }}>
        <div className="caucus-template-text">
          <h1>The General Caucus</h1>
          <h2>
            A new election system designed to
            <br />
            defeat tyranny and oligarchy.{" "}
          </h2>
          <p>
            The General Caucus is a series of tiered meetings for selecting
            politicians to specific offices, beginning at the precinct level
            where registered voters gather to choose delegates for the following
            caucus. At each precinct caucus, attendees debate relevant issues
            and group themselves with a potential delegate until one group
            achieves a simple majority. All office holders selected must come
            from the caucuses. State legislature districts are used because they
            are designed based on roughly equal population size.
          </p>
          <p>
            Precinct caucuses will select all delegates for governor, senator,
            etc. but the following caucuses will be separated depending on the
            office. There may be a final state-wide caucus, i.e. governor or
            senator, but can also have regional caucuses, i.e. representative or
            elector. Ideally discussions will focus on issues relevant to the
            specific office being filled such as local matters for mayoral
            selections, federal issues for senatorial choices, and so forth.
          </p>
          <p>
            Ultimately, the general caucus is a system that defends the people
            from perverse interests of the oligarchs and the populist rhetoric
            of tyrannically-minded candidates.{" "}
          </p>
        </div>
        <div class="caucus-template-img">
          <img src="./generalcaucusdiagram.png" alt="The General Caucus" />
        </div>
      </section>

      <section className="caucus-template">
        <div className="caucus-template-text">
          <h1>Eliminates Money in Politics</h1>
          <h2>
            The General Caucus does not require
            <br />
            money to operate.{" "}
          </h2>
          <p>
            The General Caucus is a series of meetings by caucus-goers and
            delegates which does not need money to operate. There are no
            individual camapigns that need vast sums of money to sway voters
            with campaign commercials. It's not an election, it's a caucus!{" "}
          </p>
        </div>
        <div class="caucus-template-img">
          <img src="./moneyoutofpolitics.png" alt="Money Out of Politics" />
        </div>
      </section>

      <section className="caucus-template" style={{ backgroundColor: "white" }}>
        <div className="caucus-template-text">
          <h1>No More Political Parties</h1>
          <h2>
            All office holders must come
            <br />
            from the General Caucus.{" "}
          </h2>
          <p>
            The influence of the parties is severaly reduced. Any potential
            office holder is required to pass through the precinct caucus, state
            legislature district caucus, and the final state-wide caucus. Even
            if parties nominate a candidate, that nominee must attend and be
            chosen at all three caucuses.{" "}
          </p>
        </div>
        <div class="caucus-template-img">
          <img src="./nopartiesimg.png" alt="No More Parties" />
        </div>
      </section>

      <section className="caucus-template">
        <div className="caucus-template-text">
          <h1>Media Influence is Mitigated</h1>
          <h2>Mass Media and Social Media Has Minimal Effect</h2>
          <p>
            With the General Caucus, there are millions of people involved at
            the precinct level with thousands of potential delegates to the
            state legislature district caucus. Both the legacy and social media
            are not able to concentrate their power on any particular candidates
            with so many people involved in the process.
          </p>
          <p>
            This will increase voter independence as voters engage directly with
            each other rather than being manipulated by media narratives. So
            much of the media’s influence revolves around very base issues and
            usually prioritizes sensationalism over substance.{" "}
          </p>
        </div>
        <div class="caucus-template-img">
          <img src="./nomedia.png" alt="Media Influence Mitigated" />
        </div>
      </section>

      <section className="caucus-template" style={{ backgroundColor: "white" }}>
        <div className="caucus-template-text">
          <h1>Additional Benefits of the General Caucus:</h1>
          <p>• No Ballots to Steal or Manipulate</p>
          <p>• Populism Defeated</p>
          <p>• Tyrannically-minded Candidates Become Irrelvant</p>
          <p>• Foreign Intelligence Influence Eliminated</p>
          <p>• No tribalism</p>
          <p>• Horce Race Removed, Issues Become Priority</p>
        </div>
      </section>
    </section>
  );
};

export default GeneralCaucus;
